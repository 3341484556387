export default {
  get: () => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search.slice(1))
    const { pathname } = url
    const mealPlanId = searchParams.get('weeklymenuid')

    let dayCount = 1
    let dayParam = `day${dayCount}`
    const mealPlanDays = []
    while (searchParams.has(dayParam)) {
      const dayStr = searchParams.get(dayParam).split('+') || []
      const recipeUids = []
      const lockedItems = []

      dayStr.forEach((str, index) => {
        const splittedId = str.split('-')
        const [recipeUid, lockedId] = splittedId
        if (lockedId) {
          lockedItems[index] = lockedId
        }
        recipeUids.push(recipeUid)
      })

      mealPlanDays.unshift({
        recipeUids: recipeUids.reverse(),
        lockedItems
      })
      dayCount += 1
      dayParam = `day${dayCount}`
    }

    const tags = searchParams.has('tags') ? searchParams.get('tags').split('+') : []
    const days = searchParams.get('days')
    const portions = searchParams.get('portions')

    return {
      mealPlanDays,
      tags,
      pathname,
      mealPlanId,
      days,
      portions
    }
  },
  save: (pathname = window.location.pathname, mealPlan, tags, replace) => {
    let dayCount = 1
    const searchParams = new URLSearchParams()
    mealPlan.forEach((day) => {
      const dayStr = []
      day.recipeCards.forEach((recipe, index) => {
        if (day.lockedItems && day.lockedItems[index]) {
          dayStr.push(`${recipe.uid}-${day.lockedItems[index]}`)
        } else {
          dayStr.push(`${recipe.uid}`)
        }
      })

      if (searchParams.has(`day${dayCount}`)) {
        searchParams.set(`day${dayCount}`, dayStr.join('+'))
      } else {
        searchParams.append(`day${dayCount}`, dayStr.join('+'))
      }

      dayCount += 1
    })

    if (tags.length > 0) {
      if (searchParams.has('tags')) {
        searchParams.set('tags', `${tags.join('+')}`)
      } else {
        searchParams.append('tags', `${tags.join('+')}`)
      }
    }
    if (replace) {
      window.history.replaceState({}, '', `${pathname}?${searchParams.toString()}`)
    } else {
      window.history.pushState({}, '', `${pathname}?${searchParams.toString()}`)
    }
    const event = new CustomEvent('urlchanged', {
      bubbles: true
    })
    document.body.dispatchEvent(event)
  },
  updatePathname(pathname, mealPlanPresetId) {
    let url = `${pathname.toLowerCase()}`
    if (mealPlanPresetId) {
      url += `?weeklymenuid=${mealPlanPresetId}`
    }

    window.history.pushState({}, '', url)
    const event = new CustomEvent('urlchanged', {
      bubbles: true
    })
    document.body.dispatchEvent(event)
  },
  convertIfOld() {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search.slice(1))
    let dayIndex = 1

    const getNotEmptyDay = () => {
      const param = `day${dayIndex}`
      if (searchParams.has(param) && searchParams.get(param)) {
        return searchParams.get(param)
      }
      if (searchParams.has(`day${dayIndex + 1}`)) {
        dayIndex += 1
        getNotEmptyDay()
      }
      return null
    }

    const dayToCheckId = getNotEmptyDay()

    if (dayToCheckId && !dayToCheckId.startsWith('rdb:')) {
      const newSearchParams = new URLSearchParams()
      const {
        mealPlanDays,
        tags,
        pathname,
        mealPlanId
      } = this.get()

      const newMealPlanDays = mealPlanDays.reverse().map(({
        recipeUids: oldRecipeId, lockedItems: oldLockedId
      }) => {
        const recipeUids = oldRecipeId.reverse().map(id => `rdb:${id}`)
        const lockedItems = oldLockedId.reverse().map(id => `tdb:${id}`)

        return {
          recipeUids,
          lockedItems
        }
      })

      const newTags = tags.map(tag => `tdb:${tag}`)

      let dayCount = 1

      newMealPlanDays.forEach((day) => {
        const dayStr = []
        day.recipeUids.forEach((uid, index) => {
          if (day.lockedItems && day.lockedItems[index]) {
            dayStr.push(`${uid}-${day.lockedItems[index]}`)
          } else {
            dayStr.push(`${uid}`)
          }
        })
        newSearchParams.append(`day${dayCount}`, dayStr.join('+'))
        dayCount += 1
      })

      if (newTags.length > 0) {
        newSearchParams.append('tags', `${newTags.join('+')}`)
      }
      if (mealPlanId) {
        newSearchParams.append('weeklymenuid', mealPlanId)
      }
      window.history.replaceState({}, '', `${pathname}?${newSearchParams.toString()}`)
    }
  }
}
