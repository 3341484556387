<template>
  <div class="c-mealplan-recipe__menu-list">
    <ul class="u-bare-list u-font-size-large">
      <li
        class="c-mealplan-recipe__menu-item u-flex u-flex-align-center"
        @click="generateRecipe"
      >
        <Icon
          :icon="iconRefreshArrows"
          :size="16"
          stroke="black"
          class="u-mr--s"
        />
        {{ translations.addRecipeButtonText }}
      </li>
      <li
        class="c-mealplan-recipe__menu-item u-flex u-flex-align-center"
        @click="searchRecipe"
      >
        <Icon
          :icon="iconSearch"
          :size="16"
          stroke="black"
          class="u-mr--s"
        />
        {{ translations.recipeCardMenuSearchText }}
      </li>
    </ul>
  </div>
</template>

<script>
import iconRefreshArrows from '@ds/svg/icons/stroke/refresh-arrows.svg'
import iconSearch from '@ds/svg/icons/stroke/search.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Purchase/mealplan-store/store'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    translations: {
      type: Object
    }
  },
  data() {
    return {
      iconSearch,
      iconRefreshArrows,
      store
    }
  },
  methods: {
    searchRecipe() {
      const { day, index } = this.store.state.activeNewRecipeMenu
      this.store.state.activeNewRecipeMenu = null
      this.store.state.searchRecipeModal = {
        day, index
      }
    },
    async generateRecipe() {
      const { day } = this.store.state.activeNewRecipeMenu
      try {
        await this.store.dispatch('addRecipe', { dayIndex: day })
        tracker.track({
          event: 'mealPlanAddRecipe'
        })
      } catch (e) {
        this.recipeError = true
      }
      this.store.state.activeNewRecipeMenu = null
    }
  }
}
</script>
