import recipeApi from '@/CVI/Recipe/lib/api'
import random from '@/CVI/WebCore/lib/random'
import saveMealPlan from '@/CVI/Purchase/mealplan-store/save-meal-plan'
import updateMatchCount from '@/CVI/Purchase/mealplan-store/update-match-count'
import { lockMealTypeOnRecipe } from '@/CVI/Purchase/mealplan-store/lock-mealtypes'


const shuffleRecipe = async ({ state }, {
  dayIndex, itemIndex, tagUids = [], excludeUids = [], saveState = true
}) => {
  const day = state.mealPlanDays[dayIndex]
  const { categoryTags } = state

  const matchCount = state.mealPlanDays[dayIndex].matchCounts
    ? state.mealPlanDays[dayIndex].matchCounts[itemIndex] : 0

  const recipeUids = day.recipeCards
    .map(card => card?.uid)
    .reduce((a, b) => a.concat(b), excludeUids)
    .filter(uid => uid)

  const isMealTypeLocked = day.lockedItems && day.lockedItems[itemIndex]


  const randomSkip = matchCount != 0
    ? random.get(matchCount - 1)
    : 0

  const { data } = await recipeApi.recipes.post({
    excludedUids: recipeUids,
    tagUids: isMealTypeLocked ? [...state.selectedTags, ...tagUids, ...categoryTags] : [...state.selectedTags, ...categoryTags],
    take: 1,
    skip: randomSkip
  })

  if (data.recipes.length > 0) {
    updateMatchCount({ state }, {
      dayIndex, itemIndex, matchCount: data.total
    })

    const [recipe] = data.recipes
    const recipes = state.mealPlanDays[dayIndex].recipeCards

    recipes[itemIndex] = recipe
    state.mealPlanDays[dayIndex].recipeCards = [...recipes]
    let tag
    if (recipe.mealTypes.length > 1 && isMealTypeLocked) {
      tag = day.lockedItems[itemIndex]
    } else {
      tag = (recipe.mealTypes && recipe.mealTypes[0]) ? recipe.mealTypes[0].id : null
    }
    lockMealTypeOnRecipe({ state }, { dayIndex, itemIndex, tag })
  } else {
    state.mealPlanDays[dayIndex].recipeCards.splice(itemIndex, 1)
  }
  if (saveState) {
    saveMealPlan({ state })
  }
}

export default shuffleRecipe
