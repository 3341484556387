<template>
  <div>
    <h4
      v-if="$mq == 'desktop'"
      class="u-mb--m"
    >
      {{ model.heading }}
    </h4>
    <div
      ref="container"
      class="c-mealplan__filter-content"
    >
      <Dropdown
        v-model="activeWeeklySelector"
        class="u-mb--s"
        :empty-title="emptyPredefinedTitle"
        :items="predefinedSelector"
        @on-click="onSelectorChange"
      />
      <div>
        <AmountInput
          v-model="store.state.portions"
          class="u-mb--s"
          :singular-text="model.portionsSingularText"
          :plural-text="model.portionsPluralText"
          :max-amount="20"
        />
        <AmountInput
          v-model="days"
          class="u-mb--l"
          :singular-text="model.daysSingularText"
          :plural-text="model.daysPluralText"
          :max-amount="20"
        />
        <strong v-show="store.state.showTags">{{ model.tagFiltersHeading }}</strong>
        <Accordion
          v-show="store.state.showTags"
          class="c-accordion--default u-mt--s u-mb--m"
        >
          <AccordionItem
            :id="'Filters'"
            ref="accordion"
            v-model="activeAccordionItem"
          >
            <template v-slot:title>
              <span>{{ tagsLabel }}</span>
            </template>
            <div>
              <div
                v-for="(tag, itemIndex) in model.tags"
                :key="itemIndex"
              >
                <TagItem
                  :model="tag"
                />
              </div>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
    <div
      v-if="$mq != 'desktop'"
      class="c-mealplan__filter-bottom-bar">
      <div class="c-mealplan__filter-bottom-inner u-flex u-flex-direction-column">
        <template v-if="store.state.totalCount && !activeWeeklySelector">
          {{ store.state.totalCount }} {{ model.matchCountText }}
        </template>
        <div>
          <button
            class="c-button c-button--light u-mt--s u-width-100"
            @click="closeModal"
          >
            <Icon
              class="u-mr--xs"
              :icon="iconCheck"
              :size="16"
              :fill="'black'"
            />
            {{ model.closeModalText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import iconRefreshArrows from '@ds/svg/icons/stroke/refresh-arrows.svg'
import iconCheck from '@ds/svg/icons/bold/check-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Accordion from '@/CVI/WebCore/components/Accordion.vue'

import AccordionItem from '@/CVI/WebCore/components/AccordionItem.vue'
import TagItem from '@/CVI/Purchase/components/CustomMealPlan/TagItem.vue'
import store from '@/CVI/Purchase/mealplan-store/store'
import AmountInput from '@/CVI/Purchase/components/CustomMealPlan/AmountInput.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import Dropdown from '@/CVI/WebCore/components/Dropdown.vue'


export default {
  components: {
    Accordion,
    AccordionItem,
    AmountInput,
    TagItem,
    Icon,
    Dropdown
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    weeklySelector: {
      type: Object,
      required: true
    },
    activeSelector: {
      type: Object,
      required: true
    }

  },
  data() {
    return {
      store,
      isLoading: false,
      iconRefreshArrows,
      activeAccordionItem: 'Filters',
      iconCheck,
      oldHash: ''
    }
  },
  computed: {
    days: {
      get() {
        return this.store.state.days
      },
      set(dayCount) {
        this.store.dispatch('updateDaysCount', { dayCount })
      }
    },
    tagsLabel() {
      const { selectedTags } = this.store.state

      if (selectedTags.length) {
        if (selectedTags.length > 1) {
          return `${selectedTags.length} ${this.model.selectedFiltersHeadingPlural || ''}`
        }
        return `${this.model.tags.find(tag => tag.id == selectedTags[0]).name} ${this.model.selectedFiltersHeadingSingular || ''}`
      }
      return this.model.groupHeading
    },
    matchedHash() {
      return `${this.store.state.days}+${this.store.state.selectedTags.join('+')}`
    },
    emptyPredefinedTitle() {
      return this.weeklySelector.mealPlanCategories.find(({ id }) => id == null).heading
    },
    predefinedSelector() {
      return this.weeklySelector.mealPlanCategories
        .map(({
          id, heading, customHeading, mealPlanPresetId
        }) => ({
          id: this.generateId(id, mealPlanPresetId), name: customHeading || heading, isMarked: !!customHeading
        }))
    },
    activeWeeklySelector: {
      get() {
        return this.generateId(this.activeSelector.id, this.activeSelector.mealPlanPresetId) || 0
      },
      set(value) {
        const { heading, url, mealPlanPresetId: presetId } = this.weeklySelector.mealPlanCategories
          .find(({ id, mealPlanPresetId, isCustomCategory }) => {
            if (isCustomCategory) {
              return isCustomCategory
            }
            return id == this.restoreId(value, mealPlanPresetId)
          })

        tracker.track({
          mealPlanCategory: heading,
          event: 'mealPlanType',
          mealPlanType: heading
        })
        if (value) {
          this.store.dispatch('changeMealPlan', { url, mealPlanPresetId: presetId })
        }
      }
    }
  },
  watch: {
    days(newVal, oldVal) {
      const { mealPlanCategory } = this.store.getters
      const trackObj = {}
      if (mealPlanCategory) {
        trackObj.mealPlanCategory = mealPlanCategory
      }
      if (newVal > oldVal) {
        trackObj.event = 'mealPlanDayAdd'
      } else {
        trackObj.event = 'mealPlanDaySubtract'
      }
      tracker.track(trackObj)
    },
    'store.state.portions': {
      handler(newVal, oldVal) {
        const { mealPlanCategory } = this.store.getters
        const trackObj = {}
        if (mealPlanCategory) {
          trackObj.mealPlanCategory = mealPlanCategory
        }
        if (newVal > oldVal) {
          trackObj.event = 'mealPlanPeopleAdd'
        } else {
          trackObj.event = 'mealPlanPeopleSubtract'
        }
        tracker.track(trackObj)
      }
    },
    'store.state.mealPlanDays': {
      handler() {
        if (this.oldHash != this.matchedHash) {
          this.oldHash = this.matchedHash
          this.matchedRecipes = this.store.state.matchCount
        }
      },
      deep: true
    },
    activeAccordionItem: {
      handler() {
        this.trackAccordion()
      }
    },
    activeWeeklySelector: {
      handler(value) {
        this.store.dispatch('getTotalMatchedTagsCount')

        if (value) {
          this.$refs.accordion.close()
        } else {
          this.$refs.accordion.open()
        }
      }
    },
    'store.state.selectedTags': {
      handler() {
        this.store.dispatch('getTotalMatchedTagsCount')
      }
    }
  },
  mounted() {
    if (this.store.state.isFiltersModalVisible) {
      disablePageScroll(this.$refs.container)
    }
    this.store.dispatch('getTotalMatchedTagsCount')
  },
  beforeDestroy() {
    enablePageScroll(this.$refs.container)
  },
  methods: {
    closeModal() {
      this.store.state.isFiltersModalVisible = false
    },
    trackAccordion() {
      const { mealPlanCategory } = this.store.getters
      const trackObj = {
        event: 'mealPlanMenuTypeClick'
      }
      if (mealPlanCategory) {
        trackObj.mealPlanCategory = mealPlanCategory
      }
      tracker.track(trackObj)
    },
    onSelectorChange(value) {
      this.$emit('selector-change', value)
    },
    generateId(value, presetValue) {
      return value + presetValue
    },
    restoreId(value, presetValue) {
      return value - presetValue
    }
  }
}
</script>
