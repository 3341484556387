<template>
  <div class="c-mealplan-week">
    <div
      v-for="(week, index) in store.state.mealPlanDays"
      :key="index"
      class="c-mealplan__row c-carousel"
    >
      <Row
        :model="week"
        :day="index + 1"
        :translations="translations"
      />
    </div>
  </div>
</template>

<script>
import Row from './Row.vue'
import store from '@/CVI/Purchase/mealplan-store/store'

export default {
  components: {
    Row
  },
  props: {
    translations: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      store
    }
  }
}
</script>
