const updateMatchCount = ({ state }, { dayIndex, itemIndex, matchCount }) => {
  const day = state.mealPlanDays[dayIndex]
  if (day) {
    let { matchCounts } = state.mealPlanDays[dayIndex]
    if (!matchCounts) {
      matchCounts = []
    }
    matchCounts[itemIndex] = matchCount
    state.mealPlanDays[dayIndex].matchCounts = [...matchCounts]
  }
}

export default updateMatchCount
