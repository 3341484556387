import api from '@/CVI/Purchase/lib/api'
import recipeApi from '@/CVI/Recipe/lib/api'
import storeFactory from '@/CVI/WebCore/lib/store-export-factory'
import urlMealPlanStore from '@/CVI/Purchase/lib/urlMealPlanStore'
import random from '@/CVI/WebCore/lib/random'
import resetMatchCountForAllRecipes from '@/CVI/Purchase/mealplan-store/reset-match-count-all-recipes'
import restoreMealPlan from '@/CVI/Purchase/mealplan-store/restore-mealplan'
import generateMealPlan from '@/CVI/Purchase/mealplan-store/generate-mealplan'
import shuffleRecipe from '@/CVI/Purchase/mealplan-store/shuffle-recipe'
import updateMealTypeUrlToCustom from '@/CVI/Purchase/mealplan-store/update-mealtype-url-to-custom'
import saveMealPlan from '@/CVI/Purchase/mealplan-store/save-meal-plan'
import { lockMealTypesOnDay, lockMealTypes, lockMealTypeOnRecipe } from '@/CVI/Purchase/mealplan-store/lock-mealtypes'

const store = {
  state: {
    isFiltersModalVisible: false,
    selectedTags: [],
    mealPlanDays: [],
    filtersLoading: false,
    matchCount: 0,
    totalCount: 0,
    days: 0,
    portions: 0,
    activeNewRecipeMenu: null,
    searchRecipeModal: null,
    mealTypeTags: [],
    mealPlanSelector: [],
    mealPlanUrl: '',
    mealPlanPresetId: null,
    showTags: false,
    categoryTags: [],
    mainCourseTags: null
  },
  getters: {
    buyButtons(state) {
      return state.mealPlanDays
        .map(({ recipeCards }) => recipeCards.map(card => (card.uid ? { uid: card.uid } : null)))
        .reduce((a, b) => a.concat(b), [])
    },
    trackingData(state) {
      const products = state.mealPlanDays
        .map(({ recipeCards }) => recipeCards.map(card => card))
        .reduce((a, b) => a.concat(b), [])
        .filter(recipe => recipe)
        .map(({
          uid, title, cookingTimeText, mealTypes
        }) => ({
          price: 0,
          brand: 'Arla',
          category: window.gtmData ? window.gtmData.primaryCategory : 'Recipe',
          quantity: 1,
          id: uid,
          name: title,
          dimension55: cookingTimeText,
          dimension56: null,
          variant: mealTypes.join(',')
        }))

      return {
        event: 'checkout',
        eventCategory: 'Ecommerce',
        eventAction: 'Add to basket',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1
            },
            products
          }
        }
      }
    },
    mealPlanCategory(state) {
      const mealPlanUrl = state.mealPlanUrl.toLowerCase()
      const mealPlanActiveSelector = state.mealPlanSelector
        .find(({ url }) => mealPlanUrl == url.toLowerCase())

      if (mealPlanActiveSelector && mealPlanActiveSelector.id) {
        return mealPlanActiveSelector.heading
      }
      return undefined
    }
  },
  actions: {
    generateMealPlan,
    shuffleRecipe,
    lockMealTypeOnRecipe,
    async fetchMealPlan({ state }, { mealPlanCategoryId, mealPlanId }) {
      await restoreMealPlan({ state })
      if (state.mealPlanDays.length == 0) {
        if (mealPlanId) {
          const { data } = await api.mealPlan.getMealPlanner(mealPlanId, mealPlanCategoryId)
          state.mealPlanDays = data
        } else {
          const { data } = await api.mealPlan.get(mealPlanCategoryId)
          state.mealPlanDays = data
        }
        lockMealTypes({ state })
      }
    },
    regenerateMealPlan({ state }) {
      generateMealPlan({ state }, { changeUrlToCustom: false })
    },
    changeMealPlan({ state }, { url, mealPlanPresetId }) {
      urlMealPlanStore.updatePathname(url)
      state.mealPlanDays = []
      state.mealPlanUrl = url
      state.mealPlanPresetId = mealPlanPresetId
    },
    async addTag({ state }, { id }) {
      state.selectedTags.push(id)
      state.filtersLoading = true
      resetMatchCountForAllRecipes({ state })
      await generateMealPlan({ state })
      state.filtersLoading = false
    },
    async removeTag({ state }, { id }) {
      const { selectedTags } = state
      const item = selectedTags.find(tagId => tagId == id)

      if (item) {
        selectedTags.splice(selectedTags.indexOf(item), 1)
      }
      state.selectedTags = selectedTags
      state.filtersLoading = true
      resetMatchCountForAllRecipes({ state })
      await generateMealPlan({ state })
      state.filtersLoading = false
    },
    async addRecipe({ state }, { dayIndex }) {
      const { categoryTags, selectedTags } = state
      const excludedRecipeUids = state.mealPlanDays[dayIndex]
        .recipeCards.map(card => card?.uid)
        .filter(card => card)

      const randomSkip = state.matchCount != 0
        ? random.get(state.matchCount - 1)
        : 0
      const tags = [...selectedTags, ...categoryTags]
      if (!excludedRecipeUids.length) { // when new day added to the week
        tags.push(state.mainCourseTags)
      }
      const { data } = (
        await recipeApi.recipes.post(
          {
            tagUids: tags.filter((data, index) => { return tags.indexOf(data) === index }),
            excludedUids: excludedRecipeUids,
            take: 1,
            skip: randomSkip
          }
        )
      )
      if (data.recipes.length > 0) {
        const recipe = data.recipes.shift()
        const recipes = state.mealPlanDays[dayIndex].recipeCards.filter(card => card)
        recipes.push(recipe)
        state.mealPlanDays[dayIndex].recipeCards = recipes
        lockMealTypesOnDay({ state }, { dayIndex })
      } else {
        throw TypeError('no recipes')
      }

      state.matchCount = data.total
      saveMealPlan({ state })
    },
    addCustomRecipe({ state }, { day, index, recipe }) {
      const { categoryTags } = state
      const hasCategory = category => recipe.tagUids.indexOf(category) > -1
      const containsAllCategories = categoryTags.every(hasCategory)
      if (!categoryTags.length || !containsAllCategories) {
        updateMealTypeUrlToCustom({ state })
      }

      const recipes = state.mealPlanDays[day].recipeCards

      recipes[index] = recipe
      state.mealPlanDays[day].recipeCards = [...recipes]
      lockMealTypesOnDay({ state }, { dayIndex: day })
      saveMealPlan({ state })
    },
    async updateRecipe({ state }, { day, index, mealTypeTag }) {
      const excludedRecipeUids = state.mealPlanDays[day]
        .recipeCards.map(card => card?.uid)
        .filter(card => card)

      const { categoryTags } = state

      const { data: { recipes } } = (
        await recipeApi.recipes.post(
          {
            tagUids: [mealTypeTag, ...categoryTags],
            excludedUids: excludedRecipeUids,
            take: 1,
            skip: 0
          }
        )
      )

      if (recipes.length > 0) {
        this.addCustomRecipe({ state }, { day, index, recipe: recipes[0] })
      } else {
        throw TypeError('no recipes')
      }
    },
    async addMissingDays({ state }, { days }) {
      const { categoryTags, selectedTags } = state
      const randomSkip = state.matchCount != 0
        ? random.get(state.matchCount - 1)
        : 0
      const { data } = (
        await recipeApi.recipes.post(
          {
            tagUids: [...selectedTags, ...categoryTags],
            excludedUids: [],
            take: days,
            skip: randomSkip
          }
        )
      )

      if (data.recipes.length == days) {
        data.recipes.forEach((recipe) => {
          const amountOfDays = state.mealPlanDays.length
          state.mealPlanDays.push({
            recipeCards: [recipe]
          })
          lockMealTypesOnDay({ state }, { dayIndex: amountOfDays })
        })
      } else {
        throw TypeError('no enough recipes')
      }
      state.days = state.mealPlanDays.length
      saveMealPlan({ state }, { replace: true })
    },
    removeExtraDays({ state }, { days }) {
      state.mealPlanDays.length = days
      state.days = days
      saveMealPlan({ state }, { replace: true })
    },
    removeRecipe({ state }, { dayIndex, itemIndex }) {
      state.mealPlanDays[dayIndex].recipeCards.splice(itemIndex, 1)
      this.resetMatchCount({ state }, dayIndex, itemIndex)
      saveMealPlan({ state })
    },
    resetMatchCount({ state }, { dayIndex, itemIndex }) {
      const day = state.mealPlanDays[dayIndex]
      if (day && day.matchCounts && day.matchCounts[itemIndex]) {
        state.mealPlanDays[dayIndex].matchCounts[itemIndex] = 0
      }
    },
    async updateDaysCount({ state }, { dayCount }) {
      if (dayCount > state.days) {
        state.days += 1
        state.mealPlanDays.push({ recipeCards: [] })
        await this.addRecipe({ state }, { dayIndex: state.days - 1 })
      } else {
        state.days -= 1
        state.mealPlanDays.pop()
        saveMealPlan({ state })
      }
    },
    async getTotalMatchedTagsCount({ state }) {
      const { categoryTags, selectedTags } = state
      const { data } = (
        await recipeApi.recipes.post(
          {
            tagUids: [...selectedTags, ...categoryTags],
            excludedUids: [],
            take: 1,
            skip: 0
          }
        )
      )
      state.totalCount = data.total
    },
    updateShowTags({ state }, { isCustomCategory }) {
      state.showTags = isCustomCategory
    },
    updateCategoryTags({ state }, { categoryTags }) {
      state.categoryTags = categoryTags
    }
  }
}


export default storeFactory.create(store)
