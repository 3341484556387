export const lockMealTypeOnRecipe = ({ state }, { dayIndex, itemIndex, tag }) => {
  const day = state.mealPlanDays[dayIndex]
  if (!day.lockedItems) {
    day.lockedItems = []
  }

  day.lockedItems[itemIndex] = tag
}

export const lockMealTypesOnDay = ({ state }, { dayIndex }) => {
  const day = state.mealPlanDays[dayIndex]
  day.recipeCards.forEach((recipe, itemIndex) => {
    const tag = (recipe.mealTypes && recipe.mealTypes[0]) ? recipe.mealTypes[0].id : null
    lockMealTypeOnRecipe({ state }, { dayIndex, itemIndex, tag })
  })
}


export const lockMealTypes = ({ state }) => {
  state.mealPlanDays.forEach((day, dayIndex) => {
    lockMealTypesOnDay({ state }, { dayIndex })
  })
}
