<template>
  <button
    class="c-button--icon-large c-button--icon-round c-button--icon-white"
    @click="regenerate"
  >
    <Icon
      :icon="iconRefreshArrows"
      :size="24"
      stroke="black"
    />
  </button>
</template>

<script>
import iconRefreshArrows from '@ds/svg/icons/stroke/refresh-arrows.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    trackingEventName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconRefreshArrows
    }
  },
  methods: {
    regenerate() {
      this.$emit('regenerate')
      if (this.trackingEventName) {
        tracker.track({
          event: this.trackingEventName
        })
      }
    }
  }
}
</script>
