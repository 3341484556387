<template>
  <div
    class="c-amount-input u-flex"
  >
    <button
      class="c-amount-input__decrease c-button--icon-large c-button--icon-round c-button--icon-border c-button--icon-white c-button--icon-hover-dark"
      :disabled="value == 1"
      @click="decreaseAmount()"
    >
      <Icon
        :icon="iconSubtract"
        :size="16"
        stroke="black"
      />
    </button>
    <div class="c-amount-input__label u-flex-inline u-flex-align-center u-flex-justify-center u-font-bold">
      {{ label }}
    </div>
    <button
      class="c-amount-input__increase c-button--icon-large c-button--icon-round c-button--icon-border c-button--icon-white c-button--icon-hover-dark"
      :disabled="value == maxAmount"
      @click="increaseAmount()"
    >
      <Icon
        :icon="iconAdd"
        :size="16"
        stroke="black"
      />
    </button>
  </div>
</template>

<script>
import iconAdd from '@ds/svg/icons/stroke/add.svg'
import iconSubtract from '@ds/svg/icons/stroke/subtract.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    singularText: {
      type: String,
      default: ''
    },
    pluralText: {
      type: String,
      default: ''
    },
    maxAmount: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      iconAdd,
      iconSubtract
    }
  },
  computed: {
    label() {
      return this.value == 1 ? `${this.value} ${this.singularText}` : `${this.value} ${this.pluralText}`
    }
  },
  methods: {
    increaseAmount() {
      this.$emit('input', this.value < this.maxAmount ? this.value + 1 : this.value)
    },
    decreaseAmount() {
      this.$emit('input', this.value > 1 ? this.value - 1 : this.value)
    }
  }
}
</script>
