import saveMealPlan from '@/CVI/Purchase/mealplan-store/save-meal-plan'
import shuffleRecipe from '@/CVI/Purchase/mealplan-store/shuffle-recipe'
import updateMealTypeUrlToCustom from '@/CVI/Purchase/mealplan-store/update-mealtype-url-to-custom'

const generateMealPlan = async ({ state }, { changeUrlToCustom = true } = {}) => {
  if (changeUrlToCustom) {
    updateMealTypeUrlToCustom({ state })
  }

  function getRecipeUids() {
    return state.mealPlanDays
      .map(({ recipeCards }) => recipeCards.map(card => card?.uid))
      .reduce((a, b) => a.concat(b), [])
      .filter(uid => uid)
  }

  // eslint-disable-next-line no-plusplus
  for (let dayIndex = 0; dayIndex < state.mealPlanDays.length; dayIndex++) {
    const { recipeCards, lockedItems } = state.mealPlanDays[dayIndex]
    if (!recipeCards.length) {
      const recipeUids = getRecipeUids()
      const tagUids = lockedItems && lockedItems[0] ? [lockedItems[0]] : []
      // eslint-disable-next-line no-await-in-loop
      await shuffleRecipe({ state }, {
        dayIndex,
        itemIndex: 0,
        tagUids,
        excludeUids: recipeUids,
        saveState: false
      })
    }
    // eslint-disable-next-line no-plusplus
    for (let cardIndex = 0; cardIndex < recipeCards.length; cardIndex++) {
      const recipeUids = getRecipeUids()
      const tagUids = lockedItems && lockedItems[cardIndex] ? [lockedItems[cardIndex]] : []
      // eslint-disable-next-line no-await-in-loop
      await shuffleRecipe({ state }, {
        dayIndex,
        itemIndex: cardIndex,
        tagUids,
        excludeUids: recipeUids,
        saveState: false
      })
    }
  }
  saveMealPlan({ state })
}

export default generateMealPlan
