<template>
  <button
    class="c-button--icon-large c-button--icon-round"
    @click="share"
  >
    <Icon
      :icon="iconShare"
      :size="24"
      stroke="black"
    />
  </button>
</template>

<script>
import iconShare from '@ds/svg/icons/stroke/share-1.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  data() {
    return {
      iconShare
    }
  },
  methods: {
    async share() {
      const url = window.location.href
      const { title } = window.document
      if (window.navigator.share) {
        await window.navigator.share({
          title,
          url
        })
      }
    }
  }
}
</script>
