<template>
  <button
    ref="button"
    class="c-button c-button--light u-flex-shrink-0 u-text-align--center"
    @click="regenerate"
  >
    <Icon
      class="u-mr--xs"
      :icon="iconRefreshArrows"
      :size="16"
      :stroke="'black'"
    />
    {{ text }}
  </button>
</template>

<script>
import iconRefreshArrows from '@ds/svg/icons/stroke/refresh-arrows.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Purchase/mealplan-store/store'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    text: {
      type: String,
      required: true
    },
    trackingEventName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconRefreshArrows,
      store
    }
  },
  methods: {
    async regenerate(event) {
      if (event instanceof MouseEvent) {
        this.$refs.button.blur()
      }
      await this.store.dispatch('regenerateMealPlan')

      const { mealPlanCategory } = this.store.getters
      if (this.trackingEventName) {
        const trackObj = {
          event: this.trackingEventName
        }
        if (mealPlanCategory) {
          trackObj.mealPlanCategory = mealPlanCategory
        }
        tracker.track(trackObj)
      }
    }
  }
}
</script>
