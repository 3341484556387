<template>
  <div class="c-mealplan__toolbar u-flex">
    <button
      class="c-button--no-styles u-flex u-flex-align-center u-text-nounderline"
      @click="store.state.isFiltersModalVisible = true"
    >
      <span class="c-button--icon-large c-button--icon-round">
        <Icon
          :icon="iconFilters"
          :size="24"
          stroke="black"
        />
      </span>
      <span class="u-text-uppercase u-font-bold u-font-size-medium">
        {{ model.heading }}
      </span>
    </button>
    <div class="u-flex u-ml--a u-flex-align-center">
      <MobileShare
        v-if="supportWebShareApi"
        class="u-mr--xs"
      />
      <ShareAction
        v-if="!supportWebShareApi"
        class="u-mr--xs"
        :model="shareModel"
        popover-position="bottom"
        popover-position-mobile="bottom"
      />
      <RegenerateButton
        tracking-event-name="mealPlanRegeneratePlan"
        @regenerate="regenerate"
      />
      <BuyButtonMobile
        class="u-ml--xs"
        :model="buyButtonModel"
      />
    </div>
  </div>
</template>

<script>
import iconFilters from '@ds/svg/icons/stroke/filters.svg'
import store from '@/CVI/Purchase/mealplan-store/store'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import RegenerateButton from '@/CVI/Purchase/components/CustomMealPlan/RegenerateButton.vue'
import BuyButtonMobile from '@/CVI/Purchase/components/BuyButton.vue'
import MobileShare from '@/CVI/WebCore/components/MobileNativeShare.vue'
import ShareAction from '@/CVI/Recipe/components/ShareAction.vue'

export default {
  components: {
    Icon,
    RegenerateButton,
    BuyButtonMobile,
    MobileShare,
    ShareAction
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    buyButton: {
      type: Object,
      required: true
    },
    shareModel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconFilters,
      store,
      buyButtonModel: this.buyButton,
      supportWebShareApi: window.navigator.share
    }
  },
  methods: {
    async regenerate() {
      await this.store.dispatch('regenerateMealPlan')
    }
  }
}
</script>
