<template>
  <div class="c-mealplan u-flex">
    <div class="c-mealplan__content o-content-box u-flex u-flex-direction-column">
      <Breadcrumbs
        class="u-mb--xs"
        :model="breadcrumbs"
      />
      <div class="u-flex u-flex-direction-column u-mb--s">
        <div class="u-mb--m">
          <h1 class="u-m--0 u-mb--s u-flex-shrink-0">
            {{ heading }}
          </h1>
          <p class="u-font-size-large u-width-720 u-m--0">
            {{ description }}
          </p>
        </div>
        <div
          v-if="$mq == 'desktop'"
          class="u-flex-inline u-flex-align-start u-flex-wrap"
        >
          <div class="u-flex u-flex-align-center u-flex-wrap">
            <RegenerateButtonWithText
              :text="filters.regenerateButtonText"
              tracking-event-name="mealPlanRegeneratePlan"
            />
            <transition
              name="fade"
              mode="out-in"
              tag="div"
            >
              <span
                v-if="store.state.totalCount"
                class="u-ml--s u-mt--s u-mb--s"
              >
                {{ store.state.totalCount }} {{ filters.matchCountText }}
              </span>
            </transition>
          </div>
          <div class="u-flex u-flex-align-center u-flex-shrink-0 u-ml--a">
            <ShareAction
              class="u-mr--s"
              :model="model.share"
              popover-position="bottom"
              popover-position-mobile="bottom"
              icon-placement="left"
              :show-title="true"
              :tracking-obj="shareTrackingObj"
            />
            <BuyButtonMultiple
              v-if="purchaseEnabled"
              class="u-ml--s"
              :model="buyButtonModel"
            />
          </div>
        </div>
      </div>
      <transition
        name="fade-mealplan"
        mode="out-in"
        tag="div"
      >
        <Loader
          v-if="isLoading || loadingOnlyRecipes"
          :key="'loader'"
          class="u-flex u-mt--m u-ml--a u-mr--a"
          size="large"
          color="medium-gray"
        />
        <Week
          v-if="!isLoading && !loadingOnlyRecipes"
          :key="'mealplanweek'"
          :translations="model"
        />
      </transition>
    </div>
    <div
      v-if="$mq == 'desktop'"
      class="c-mealplan__aside o-content-box u-flex-shrink-0"
    >
      <transition-group
        name="fade-mealplan"
        mode="out-in"
      >
        <Filters
          v-if="!isLoading"
          :key="'filters'"
          :model="filters"
          :active-selector="activeSelector"
          :weekly-selector="selector"
          @selector-change="onSelectorChange"
        />
        <Loader
          v-if="isLoading"
          :key="'loader'"
          class="u-flex u-mt--m u-ml--a u-mr--a"
          size="large"
          color="medium-gray"
        />
      </transition-group>
    </div>
    <Modal
      v-if="$mq != 'desktop' && !isLoading"
      v-model="store.state.isFiltersModalVisible"
      class="c-mealplan__filter-modal"
      :title="filters.heading"
      :is-title-centered="false"
    >
      <Filters
        :model="filters"
        :active-selector="activeSelector"
        :weekly-selector="selector"
        @selector-change="onSelectorChange"
      />
    </Modal>
    <Toolbar
      v-if="$mq != 'desktop'"
      :model="filters"
      :buy-button="buyButtonModel"
      :share-model="model.share"
    />
    <Modal
      v-if="$mq == 'mobile' || $mq == 'tablet'"
      v-model="isNewRecipeMenuModalVisible"
      :title="model.menuNewRecipeMobileTitle"
      :is-title-centered="false"
      :only-scroll-in-modal="false"
      class="c-mealplan__menu-modal"
    >
      <div class="u-width-100 u-mt--m u-mb--m">
        <NewRecipeCardMenuItems :translations="model" />
      </div>
    </Modal>
    <SearchRecipeModal
      :model="searchModal"
      :purchase-enabled="purchaseEnabled"
    />
  </div>
</template>

<script>
import store from '@/CVI/Purchase/mealplan-store/store'

import Breadcrumbs from '@/CVI/WebCore/components/Breadcrumbs.vue'
import Filters from '@/CVI/Purchase/components/CustomMealPlan/Filters.vue'
import Toolbar from '@/CVI/Purchase/components/CustomMealPlan/MobileToolbar.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import Week from '@/CVI/Purchase/components/CustomMealPlan/Week.vue'
import RegenerateButtonWithText from '@/CVI/Purchase/components/CustomMealPlan/RegenerateButtonWithText.vue'
import BuyButtonMultiple from '@/CVI/Purchase/components/BuyButtonMultiple.vue'
import ShareAction from '@/CVI/Recipe/components/ShareAction.vue'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import NewRecipeCardMenuItems from '@/CVI/Purchase/components/CustomMealPlan/NewRecipeCardMenuItems.vue'
import SearchRecipeModal from '@/CVI/Purchase/components/CustomMealPlan/SearchRecipeModal.vue'
import urlMealPlanStore from '@/CVI/Purchase/lib/urlMealPlanStore'

export default {
  components: {
    Breadcrumbs,
    Filters,
    Toolbar,
    Loader,
    RegenerateButtonWithText,
    Week,
    BuyButtonMultiple,
    ShareAction,
    Modal,
    NewRecipeCardMenuItems,
    SearchRecipeModal
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    breadcrumbs: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    buyButtonText: {
      type: String,
      required: true
    },
    searchModal: {
      type: Object,
      required: true
    },
    selector: {
      type: Object,
      required: true
    },
    mainCourseTags: {
      type: Array,
      default: () => []
    },
    purchaseEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store,
      isLoading: true,
      loadingOnlyRecipes: false,
      isNewRecipeMenuModalVisible: false
    }
  },
  computed: {
    activeSelector() {
      const { mealPlanUrl, mealPlanPresetId: mealPlanId } = this.store.state

      if (mealPlanId) {
        return this.selector
          .mealPlanCategories.find(({ mealPlanPresetId }) => mealPlanPresetId == mealPlanId)
      }

      return this.selector
        .mealPlanCategories
        .find(({
          url, mealPlanPresetId
        }) => (!mealPlanPresetId && url.toLowerCase() == mealPlanUrl.toLowerCase())) || {}
    },
    heading() {
      return this.activeSelector?.heading
    },
    description() {
      return this.activeSelector?.description
    },
    shareTrackingObj() {
      const trackingObj = {
        event: 'mealPlanShare'
      }
      const { mealPlanCategory } = this.store.getters
      if (mealPlanCategory) {
        trackingObj.mealPlanCategory = mealPlanCategory
      }
      return trackingObj
    },
    buyButtonModel() {
      const { buyButtonText: text } = this
      return {
        text,
        buttons: []
      }
    }
  },
  watch: {
    'store.state.portions': {
      immediate: true,
      handler(portions) {
        const buttons = this.store.getters.buyButtons.map(({ uid }) => ({ uid, portionCount: portions }))
        this.buyButtonModel.buttons = buttons
      }
    },
    'store.state.mealPlanDays': {
      handler() {
        const buttons = this.store.getters.buyButtons.map(({ uid }) => ({ uid, portionCount: this.store.state.portions }))
        this.buyButtonModel.buttons = buttons
      },
      deep: true
    },
    'store.state.activeNewRecipeMenu': {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.isNewRecipeMenuModalVisible = true
          })
        } else {
          this.isNewRecipeMenuModalVisible = false
        }
      }
    },
    activeSelector: {
      handler(value) {
        const { isCustomCategory } = value
        this.store.dispatch('updateShowTags', { isCustomCategory })
      }
    }
  },
  mounted() {
    this.initialLoad()
    this.enableBrowserNavigation()
  },
  methods: {
    async initialLoad() {
      urlMealPlanStore.convertIfOld()
      const {
        defaultDaysFilterValue,
        defaultPortionsFilterValue
      } = this.filters

      const urlStore = urlMealPlanStore.get()

      const {
        days,
        pathname,
        mealPlanId
      } = urlStore

      let {
        portions
      } = urlStore

      if (!portions) {
        portions = defaultPortionsFilterValue
      }
      if (days > 20) {
        throw new Error('Days value shouldn\'t be more 20')
      }
      this.store.state.portions = parseInt(portions, 10)
      this.store.state.days = defaultDaysFilterValue
      this.store.state.mealTypeTags = this.model.mealTypeTags
      this.store.state.mealPlanSelector = this.selector.mealPlanCategories
      this.store.state.mealPlanUrl = pathname
      this.store.state.mealPlanPresetId = mealPlanId
      this.store.state.mainCourseTags = this.mainCourseTags.join('')

      this.buyButtonModel.trackingMultipleData = this.store.getters.trackingData
      this.isLoading = true
      await this.fetchMealPlan(mealPlanId)
      const currentMealPlanDaysCount = this.store.state.mealPlanDays.length
      if (days > currentMealPlanDaysCount) {
        await this.store.dispatch('addMissingDays', { days: days - currentMealPlanDaysCount })
      } else if (days && days < currentMealPlanDaysCount) {
        this.store.dispatch('removeExtraDays', { days })
      }

      this.isLoading = false
    },
    async fetchMealPlan(mealPlanId) {
      let { mealPlanCategoryId } = this.model
      const { id } = this.activeSelector
      let { tagIds: categoryTags } = this.activeSelector
      if (!categoryTags) {
        categoryTags = []
      }
      this.store.dispatch('updateCategoryTags', { categoryTags })

      if (id) {
        mealPlanCategoryId = id
      }

      this.loadingOnlyRecipes = true
      await this.store.dispatch('fetchMealPlan', { mealPlanCategoryId, mealPlanId })
      this.loadingOnlyRecipes = false
      this.isLoading = false
    },
    enableBrowserNavigation() {
      window.addEventListener('popstate', async () => {
        const { pathname, mealPlanId, days, portions } = urlMealPlanStore.get()
        this.store.state.mealPlanDays = []
        this.store.state.mealPlanUrl = pathname
        this.store.state.mealPlanPresetId = mealPlanId
        this.store.state.portions = parseInt(portions, 10) || this.filters.defaultPortionsFilterValue
        this.store.state.days = parseInt(days, 10) || this.filters.defaultDaysFilterValue
        await this.fetchMealPlan(mealPlanId)
        this.store.state.days = this.store.state.mealPlanDays.length
        this.store.state.mainCourseTags = this.mainCourseTags.join('')
      })
    },
    async onSelectorChange(value) {
      if (value) {
        const { mealPlanId } = urlMealPlanStore.get()
        await this.fetchMealPlan(mealPlanId)
      } else {
        let { tagIds: categoryTags } = this.selector
          .mealPlanCategories.find(({ isCustomCategory }) => isCustomCategory)

        if (!categoryTags) {
          categoryTags = []
        }
        this.store.dispatch('updateCategoryTags', { categoryTags })
        await this.store.dispatch('generateMealPlan')
      }
      this.store.state.days = this.store.state.mealPlanDays.length
    }
  }
}
</script>

<style lang="scss">
@use '@/CVI/Purchase/scss/mealplan.scss'

</style>
