<template>
  <ul class="c-breadcrumbs u-flex u-bare-list u-hidden-print">
    <li
      v-for="(item, index) in model.items"
      :key="index"
      class="c-breadcrumbs__item"
    >
      <a :href="item.url">{{ item.label }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
