import urlMealPlanStore from '@/CVI/Purchase/lib/urlMealPlanStore'
import resetMatchCountForAllRecipes from '@/CVI/Purchase/mealplan-store/reset-match-count-all-recipes'
import recipeApi from '@/CVI/Recipe/lib/api'

const restoreMealPlan = async ({ state }) => {
  const mealPlan = urlMealPlanStore.get()
  const { categoryTags } = state
  state.selectedTags = mealPlan.tags

  if (mealPlan.mealPlanDays.length > 0) {
    const recipeUids = mealPlan.mealPlanDays
      .map(day => day.recipeUids)
      .reduce((a, b) => a.concat(b), [])

    resetMatchCountForAllRecipes({ state })
    const { data } = await recipeApi.recipes.get(recipeUids)

    mealPlan.mealPlanDays.forEach((day) => {
      const mealPlanDay = {
        recipeCards: []
      }
      if (day.lockedItems) {
        mealPlanDay.lockedItems = day.lockedItems
      }
      day.recipeUids.forEach((recipeUid) => {
        const recipe = data.find(r => r.uid == recipeUid)
        mealPlanDay.recipeCards.unshift(recipe)
      })
      state.mealPlanDays.unshift(mealPlanDay)
    })
    state.days = mealPlan.mealPlanDays.length
  }
}

export default restoreMealPlan
