<template>
  <Modal
    v-model="showModal"
    :title="model.title"
    :is-title-centered="false"
    :width="800"
    :table-width="640"
    class="c-mealplan__recipe-modal"
    :only-scroll-in-modal="false"
  >
    <div class="c-mealplan__recipe-search-modal-input u-mb--m">
      <input
        v-model="query"
        type="text"
        class="c-text-input u-width-100 u-max-width-100"
        :placeholder="model.inputPlaceholder"
      >
      <Icon
        class="c-mealplan__recipe-search-modal-icon"
        :icon="iconSearch"
        :size="16"
        stroke="medium-gray"
      />
    </div>
    <div
      ref="container"
      class="c-mealplan__recipe-modal-recipes"
      :style="{
        paddingRight: scrollbarWidth || null
      }"
    >
      <strong
        v-if="showMatched"
      >{{ totalFoundRecipesText }}</strong>
      <p
        v-if="showNotFound"
        class="u-font-size-medium u-text-align--center u-mb--m"
      >
        {{ noFoundText }}
      </p>
      <div
        v-if="foundRecipes.length"
        class="c-mealplan-recipe-grid u-mt--s"
      >
        <RecipeCard
          v-for="(recipe) in foundRecipes"
          :key="recipe.uid"
          :model="recipe"
          :desktop="[750, 420]"
          :tablet="[670, 400]"
          :mobile="[327, 185]"
          :show-image-load-state="true"
          @click.native="selectRecipe($event,recipe, 'searchMatchedRecipe')"
        />
      </div>
      <Loader
        v-if="loadingSearch"
        :key="'loader'"
        class="u-flex u-ml--a u-mr--a"
        color="medium-gray"
      />
      <div
        v-if="foundRecipes.length"
        class="u-flex u-flex-justify-center"
      >
        <button
          v-if="(searchTake < totalFoundRecipes) && !loadingSearch"
          class="c-button c-button--dark u-mt--s u-mb--s"
          @click="getMoreSearch"
        >
          {{ model.loadMoreText }}
        </button>
      </div>
      <div
        v-if="!foundRecipes.length || showNotFound"
      >
        <div
          v-if="purchaseEnabled"
          class="u-mb--m"
        >
          <h5>{{ favoriteRecipesText }}</h5>
          <div class="u-mt--m">
            <div
              v-if="!loadingFavorites && !favoriteRecipes.length"
              class="u-font-size-medium u-text-align--center"
            >
              {{ model.noSavedRecipesPrefixText }}
              <a
                href="#"
                @click="login"
              >
                {{ model.loginText }}
              </a>
              {{ model.noSavedRecipesPostfixText }}
            </div>
            <Loader
              v-if="loadingFavorites"
              :key="'loader'"
              class="u-flex u-ml--a u-mr--a"
              color="medium-gray"
            />
            <div
              v-if="favoriteRecipes.length"
              class="c-mealplan-recipe-grid"
            >
              <RecipeCard
                v-for="(recipe) in favoriteRecipes"
                :key="recipe.uid"
                :model="recipe"
                :desktop="[750, 420]"
                :tablet="[670, 400]"
                :mobile="[327, 185]"
                :show-image-load-state="true"
                @click.native="selectRecipe($event,recipe, 'searchSavedRecipe')"
              />
            </div>
          </div>
        </div>
        <div>
          <h5>{{ popularRecipesText }}</h5>
          <div class="u-mt--m">
            <div
              v-if="popularRecipes.length"
              class="c-mealplan-recipe-grid"
            >
              <RecipeCard
                v-for="(recipe) in popularRecipes"
                :key="recipe.uid"
                :model="recipe"
                :desktop="[750, 420]"
                :tablet="[670, 400]"
                :mobile="[327, 185]"
                :show-image-load-state="true"
                @click.native="selectRecipe($event,recipe, 'popularSearchRecipe')"
              />
            </div>
            <Loader
              v-if="loadingPopular"
              :key="'loader'"
              class="u-flex u-ml--a u-mr--a"
              color="medium-gray"
            />
            <div class="u-flex u-flex-justify-center">
              <button
                v-if="(skip < totalPopularRecipes) && !loadingPopular"
                class="c-button c-button--dark u-mt--s u-mb--s"
                @click="getPopularRecipes"
              >
                {{ model.loadMoreText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import iconSearch from '@ds/svg/icons/stroke/search.svg'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Purchase/mealplan-store/store'
import api from '@/CVI/Recipe/lib/api'
import webCoreApi from '@/CVI/WebCore/lib/api'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import RecipeCard from '@/CVI/Recipe/components/RecipeCard.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import webCoreStore from '@/CVI/WebCore/store'
import getScrollbarWidth from '@/CVI/WebCore/lib/scrollbar-width'

export default {
  components: {
    Modal,
    Icon,
    Loader,
    RecipeCard
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    purchaseEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconSearch,
      store,
      webCoreStore,
      query: '',
      showModal: false,
      favoriteRecipes: [],
      foundRecipes: [],
      popularRecipes: [],
      loadingSearch: false,
      loadingFavorites: false,
      loadingPopular: false,
      cancelToken: null,
      take: 20,
      searchSkip: 0,
      searchTake: 50,
      skip: 0,
      totalFoundRecipes: 0,
      totalPopularRecipes: 0,
      totalFavoriteRecipes: 0,
      scrollbarWidth: getScrollbarWidth()
    }
  },
  computed: {
    totalFoundRecipesText() {
      return this.model.matchingRecipesText.replace('{0}', this.totalFoundRecipes)
    },
    popularRecipesText() {
      return this.model.popularRecipeText.replace('{0}', this.totalPopularRecipes)
    },
    favoriteRecipesText() {
      return this.model.favoriteRecipeText.replace('{0}', this.totalFavoriteRecipes)
    },
    noFoundText() {
      return this.model.notFoundText.replace('{0}', this.query)
    },
    showNotFound() {
      return this.query.length > 2 && !this.loadingSearch && this.foundRecipes.length == 0
    },
    showMatched() {
      return (this.query.length > 2 && !this.loadingSearch) || this.foundRecipes.length > 0
    }
  },
  watch: {
    'store.state.searchRecipeModal': {
      async handler(value) {
        if (value) {
          this.showModal = true
          if (!this.favoriteRecipes.length) {
            this.getFavorites()
          }
          if (!this.popularRecipes.length) {
            this.getPopularRecipes()
          }
        }
      }
    },
    showModal: {
      handler(value) {
        if (!value) {
          enablePageScroll(this.$refs.container)
          this.store.state.searchRecipeModal = null
        } else {
          setTimeout(() => {
            disablePageScroll(this.$refs.container)
          }, 300)
        }
      }
    },
    async query(query) {
      if (query.length < 3) {
        this.foundRecipes = []
        return
      }

      const { cancelToken, searchTake } = this
      this.searchSkip = 0

      if (cancelToken && cancelToken.cancel) {
        cancelToken.cancel()
      }

      this.cancelToken = webCoreApi.createCancelToken()
      this.loadingSearch = true
      const response = await api.recipes.post({
        take: searchTake,
        query,
        cancelToken: this.cancelToken
      }).catch((e) => {
        console.log(e)
      })
      if (response) {
        const { data: { recipes, total } } = response
        this.searchSkip += searchTake
        this.loadingSearch = false
        this.query = query
        this.foundRecipes = recipes
        this.totalFoundRecipes = total
      } else {
        this.loadingSearch = false
      }
    }
  },
  methods: {
    async getFavorites() {
      this.loadingFavorites = true
      const { data: { recipes, total } } = await api.favoriteRecipes.get(50, 0, true)
      this.favoriteRecipes = recipes
      this.totalFavoriteRecipes = total
      this.loadingFavorites = false
    },
    async getPopularRecipes() {
      this.loadingPopular = true
      const { take, skip } = this
      const { data: { recipes, total } } = await api.recipes.post({
        take, skip
      })
      this.totalPopularRecipes = total
      this.skip += take
      this.popularRecipes = this.popularRecipes.concat(recipes)
      this.loadingPopular = false
    },
    async getMoreSearch() {
      const { searchTake, searchSkip, query } = this
      this.loadingSearch = true

      const { data: { recipes } } = await api.recipes.post({
        take: searchTake,
        query,
        skip: searchSkip,
        cancelToken: this.cancelToken
      })

      this.searchSkip += searchTake
      this.foundRecipes = this.foundRecipes.concat(recipes)

      this.loadingSearch = false
    },
    selectRecipe(event, recipe, trackingEvent) {
      event.stopPropagation()
      event.preventDefault()
      const { day, index } = this.store.state.searchRecipeModal
      this.store.dispatch('addCustomRecipe', {
        day, index, recipe
      })
      tracker.track({
        event: trackingEvent,
        recipeName: recipe.title
      })
      this.showModal = false
    },
    login() {
      this.webCoreStore.state.isLoginPopupVisible = true
      this.showModal = false
    }
  }
}
</script>
