<template>
  <div
    ref="mealplanCarousel"
    class="c-carousel__container--wrapper c-mealplan__carousel"
  >
    <h3>
      {{ translations.dayText }} {{ day }}
    </h3>
    <div
      v-if="recipes.length"
      class="c-carousel__wrapper swiper-wrapper"
    >
      <Recipe
        v-for="(recipe, index) in recipes"
        :key="recipe.uid"
        :model="recipe"
        :day="day - 1"
        :index="index"
        :translations="translations"
        :locked-id="getLockedId(index)"
      />
      <AddRecipeCard
        ref="addRecipeCard"
        :day="day - 1"
        :index="recipes.length"
        :add-recipe-button-text="translations.addRecipeButtonText"
        :add-recipe-error-text="translations.addRecipeErrorText"
        :add-recipe-error-title="translations.addRecipeErrorTitle"
        :search-recipe-button-text="translations.searchRecipeButtonText"
      />
    </div>
    <div
      v-else
      class="c-carousel__wrapper swiper-wrapper"
    >
      <AddRecipeCard
        ref="addRecipeCard"
        :day="day - 1"
        :index="0"
        :add-recipe-button-text="translations.addRecipeButtonText"
        :add-recipe-error-text="translations.addRecipeErrorText"
        :add-recipe-error-title="translations.addRecipeErrorTitle"
        :search-recipe-button-text="translations.searchRecipeButtonText"
      />
    </div>
    <div class="c-carousel-navigation__wrapper">
      <button class="c-carousel-navigation swiper-button-prev">
        <Icon
          :icon="iconArrowLeft"
          :size="32"
          stroke="white"
        />
      </button>
      <button class="c-carousel-navigation swiper-button-next">
        <Icon
          :icon="iconArrowRight"
          :size="32"
          stroke="white"
        />
      </button>
    </div>
    <div class="c-carousel-pagination u-flex u-flex-justify-center u-flex-align-center" />
  </div>
</template>

<script>
import Swiper from 'swiper'
import iconArrowLeft from '@ds/svg/icons/stroke/arrow-left-1.svg'
import iconArrowRight from '@ds/svg/icons/stroke/arrow-right-1.svg'
import Recipe from '@/CVI/Purchase/components/CustomMealPlan/Recipe.vue'
import AddRecipeCard from '@/CVI/Purchase/components/CustomMealPlan/AddRecipeCard.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Purchase/mealplan-store/store'

export default {
  components: {
    Recipe,
    AddRecipeCard,
    Icon
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
    day: {
      type: Number,
      default: null
    },
    translations: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      store,
      iconArrowLeft,
      iconArrowRight,
      slider: null
    }
  },
  computed: {
    recipes() {
      return this.model.recipeCards.filter(recipe => recipe)
    },
    ids() {
      return this.recipes.map(({ uid }) => uid).join('-')
    }
  },
  watch: {
    ids: {
      handler() {
        this.$nextTick(() => {
          this.slider.update()
          this.$refs.addRecipeCard.resetErrorState()
        })
      }
    }
  },
  mounted() {
    this.initCarousel()
  },
  methods: {
    initCarousel() {
      const settings = {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        simulateTouch: false,
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 12,
        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 32
          }
        },
        pagination: {
          el: '.c-carousel-pagination',
          type: 'bullets',
          clickable: true
        },
        watchSlidesProgress: true,
        watchSlidesVisibility: true
      }
      this.slider = new Swiper(this.$refs.mealplanCarousel, settings)
      return this.slider
    },
    getLockedId(index) {
      const day = this.store.state.mealPlanDays[this.day - 1]
      return day.lockedItems ? (day.lockedItems[index] || null) : null
    }
  }
}
</script>
