<template>
  <div class="c-mealplan-recipe-item swiper-slide">
    <div class="c-meal-plan-heading u-flex u-mb--xs u-flex-justify-sides u-flex-align-center">
      <Dropdown
        v-model="activeId"
        :empty-title="translations.mealTypeSelectorEmptyText"
        size="small"
        :items="store.state.mealTypeTags"
      />
      <div class="u-ml--a">
        <div class="u-flex u-flex-align-center">
          <RegenerateButton
            class="u-mr--xxs"
            @regenerate="regenerate"
          />
          <button
            class="c-button--icon-large c-button--icon-round c-button--icon-white u-mr--xxs"
            @click="searchRecipe"
          >
            <Icon
              :icon="iconSearch"
              :size="24"
              stroke="black"
            />
          </button>
          <button
            class="c-button--icon-large c-button--icon-round c-button--icon-white"
            @click="removeRecipe"
          >
            <Icon
              :icon="iconTrash"
              :size="24"
              fill="black"
            />
          </button>
        </div>
      </div>
    </div>
    <RecipeCard
      v-if="!error"
      :model="model"
      size="large"
      :desktop="[750, 420]"
      :tablet="[670, 400]"
      :mobile="[327, 185]"
      :show-loader="store.state.filtersLoading || isLoading"
      :show-image-load-state="true"
      :save-action-tracking="saveActionTracking"
    />
    <AddRecipeCard
      v-if="error"
      ref="errorRecipeCard"
      :day="day - 1"
      :no-margin="true"
      :add-recipe-button-text="translations.addRecipeButtonText"
      :add-recipe-error-text="translations.addRecipeErrorText"
      :add-recipe-error-title="translations.addRecipeErrorTitle"
      :search-recipe-button-text="translations.searchRecipeButtonText"
    />
  </div>
</template>

<script>

import iconTrash from '@ds/svg/icons/bold/bin-1-bold.svg'
import iconSearch from '@ds/svg/icons/stroke/search.svg'

import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Purchase/mealplan-store/store'

import RecipeCard from '@/CVI/Recipe/components/RecipeCard.vue'
import Dropdown from '@/CVI/WebCore/components/Dropdown.vue'
import RegenerateButton from '@/CVI/Purchase/components/CustomMealPlan/RegenerateButton.vue'
import AddRecipeCard from '@/CVI/Purchase/components/CustomMealPlan/AddRecipeCard.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    AddRecipeCard,
    Icon,
    Dropdown,
    RecipeCard,
    RegenerateButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    translations: {
      type: Object,
      required: true
    },
    day: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    lockedId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      iconTrash,
      iconSearch,
      store,
      activeId: (this.lockedId || this.model.mealTypes[0]?.id) || 0,
      isLoading: false,
      isPopoverVisible: false,
      error: false
    }
  },
  computed: {
    saveActionTracking() {
      const trackingObj = {
        event: 'mealPlanSaveRecipe'
      }
      const { mealPlanCategory } = this.store.getters
      if (mealPlanCategory) {
        trackingObj.mealPlanCategory = mealPlanCategory
      }
      return trackingObj
    }
  },
  watch: {
    async activeId(mealTypeTag) {
      const { day, index } = this
      this.isLoading = true
      this.error = false

      const { mealPlanCategory } = this.store.getters
      const trackObj = {
        event: 'mealPlanPickMealtype',
        pickMealtype: this.store.state.mealTypeTags.find(({ id }) => id == mealTypeTag).name
      }
      if (mealPlanCategory) {
        trackObj.mealPlanCategory = mealPlanCategory
      }
      tracker.track(trackObj)

      this.lockRecipeAndResetMatchCount()
      try {
        await this.store.dispatch('updateRecipe', {
          day,
          index,
          mealTypeTag
        })
      } catch (e) {
        this.error = true
      }
      this.isLoading = false
    },
    'store.state.regenerateId': {
      handler() {
        this.regenerate()
      }
    },
    error(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.errorRecipeCard.addRecipeErrorState()
        })
      }
    }
  },
  methods: {
    removeRecipe() {
      tracker.track({
        event: 'mealEllipsis',
        mealEllipsis: 'Delete recipe'
      })
      this.store.dispatch('removeRecipe', {
        dayIndex: this.day,
        itemIndex: this.index
      })
    },
    searchRecipe() {
      const { day, index } = this
      this.store.state.searchRecipeModal = {
        day, index
      }
      const { mealPlanCategory } = this.store.getters
      const trackObj = {
        event: 'mealEllipsis',
        mealEllipsis: 'Search for a new recipe'
      }
      if (mealPlanCategory) {
        trackObj.mealPlanCategory = mealPlanCategory
      }
      tracker.track(trackObj)
    },
    lockRecipeAndResetMatchCount() {
      const { day, index } = this
      this.store.dispatch('lockMealTypeOnRecipe', { dayIndex: day, itemIndex: index, tag: this.activeId })
      this.store.dispatch('resetMatchCount', { dayIndex: day, itemIndex: index })
    },
    async regenerate() {
      this.isLoading = true
      this.error = false
      const params = {
        dayIndex: this.day,
        itemIndex: this.index,
        tagUids: [this.activeId]
      }

      const { mealPlanCategory } = this.store.getters
      const trackObj = {
        event: 'mealPlanShuffleRecipe',
        recipeName: this.model.title
      }
      if (mealPlanCategory) {
        trackObj.mealPlanCategory = mealPlanCategory
      }
      tracker.track(trackObj)

      await this.store.dispatch('shuffleRecipe', params)
      this.isLoading = false
    }
  }
}
</script>
