<template>
  <div
    class="c-add-recipe-card u-flex u-flex-direction-column swiper-slide"
    :class="{
      'c-add-recipe-card--no-margin': noMargin
    }"
  >
    <div
      class="c-add-recipe-card__wrapper"
      :class="{
        'is-error': recipeError
      }"
    >
      <span
        v-if="!recipeError && !isLoading"
        class="
          c-add-recipe__generate
          u-flex
          u-flex-direction-column
          u-flex-align-center
          u-flex-justify-center
          u-text-uppercase
          u-font-bold
        "
        @click="addRecipe"
      >
        <Icon
          class="u-mb--xs"
          :icon="iconRefreshArrows"
          :size="24"
          stroke="black"
        />
        {{ addRecipeButtonText }}
      </span>
      <span
        v-if="!recipeError && !isLoading"
        class="
          c-add-recipe__search
          u-flex
          u-flex-direction-column
          u-flex-align-center
          u-flex-justify-center
          u-text-uppercase
          u-font-bold
        "
        @click="showRecipeSearch"
      >
        <Icon
          class="u-mb--xs"
          :icon="iconSearch"
          :size="24"
          stroke="black"
        />
        {{ searchRecipeButtonText }}
      </span>
      <span
        v-if="recipeError && !isLoading"
        class="
          c-add-recipe__error-state
          u-flex
          u-flex-direction-column
          u-flex-align-center
          u-flex-justify-center
        "
      >
        <Icon
          class="u-mb--xs"
          :icon="iconAlert"
          :size="24"
          fill="error"
        />
        <strong class="u-font-size-xlarge">{{ addRecipeErrorTitle }}</strong>
        <span class="u-font-size-medium u-text-align--center">{{ addRecipeErrorText }}</span>
      </span>
      <Loader
        v-if="isLoading"
        :key="'loader'"
        class="u-flex u-mt--m u-ml--a u-mr--a"
        size="large"
        color="medium-gray"
      />
    </div>
  </div>
</template>

<script>
import iconRefreshArrows from '@ds/svg/icons/stroke/refresh-arrows.svg'
import iconSearch from '@ds/svg/icons/stroke/search.svg'
import iconAlert from '@ds/svg/icons/bold/alert-circle-bold.svg'
import store from '@/CVI/Purchase/mealplan-store/store'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon,
    Loader
  },
  props: {
    day: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    addRecipeButtonText: {
      type: String,
      default: ''
    },
    addRecipeErrorText: {
      type: String,
      default: ''
    },
    addRecipeErrorTitle: {
      type: String,
      default: ''
    },
    searchRecipeButtonText: {
      type: String,
      default: ''
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconRefreshArrows,
      iconSearch,
      iconAlert,
      store,
      isLoading: false,
      recipeError: false
    }
  },
  methods: {
    async addRecipe() {
      this.isLoading = true
      try {
        const { mealPlanCategory } = this.store.getters
        const trackObj = {
          event: 'addAdditionalRecipe',
          addAdditionalRecipe: 'Gegenerate a recipe'
        }
        if (mealPlanCategory) {
          trackObj.mealPlanCategory = mealPlanCategory
        }
        tracker.track(trackObj)
        await this.store.dispatch('addRecipe', { dayIndex: this.day })
      } catch (e) {
        this.recipeError = true
      }
      this.isLoading = false
    },
    resetErrorState() {
      this.recipeError = false
    },
    addRecipeErrorState() {
      this.recipeError = true
    },
    showRecipeSearch() {
      const { day, index } = this
      this.store.state.searchRecipeModal = {
        day, index
      }
      const { mealPlanCategory } = this.store.getters
      const trackObj = {
        event: 'addAdditionalRecipe',
        addAdditionalRecipe: 'Search for a recipe'
      }
      if (mealPlanCategory) {
        trackObj.mealPlanCategory = mealPlanCategory
      }
      tracker.track(trackObj)
    }
  }
}
</script>
