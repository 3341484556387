<template>
  <div
    class="c-mealplan__tag-checkbox-container"
  >
    <div
      @click.prevent.self="toggleTag(!isSelected)"
    >
      <Checkbox
        ref="checkbox"
        v-model="isSelected"
        class="c-mealplan__tag-checkbox"
        appearance="checkbox"
        @input="toggleTag($event)"
      />
      {{ model.name }}
    </div>
  </div>
</template>

<script>
import Checkbox from '@/CVI/WebCore/components/Checkbox.vue'
import store from '@/CVI/Purchase/mealplan-store/store'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Checkbox
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      isSelected: false
    }
  },
  watch: {
    'store.state.selectedTags': {
      handler() {
        this.updateState()
      }
    }
  },
  mounted() {
    this.updateState()
  },
  methods: {
    toggleTag(value) {
      this.isSelected = value
      const { id } = this.model
      if (this.isSelected) {
        this.store.dispatch('addTag', { id })

        const { mealPlanCategory } = this.store.getters
        const trackObj = {
          event: 'mealPlanFilterSelect',
          mealPlanFilter: this.model.name
        }
        if (mealPlanCategory) {
          trackObj.mealPlanCategory = mealPlanCategory
        }
        tracker.track(trackObj)
      } else {
        this.store.dispatch('removeTag', { id })
      }
    },
    updateState() {
      const selectedTag = this.store.state.selectedTags.find(tag => tag == this.model.id)
      this.isSelected = !!selectedTag
    }
  }
}
</script>
